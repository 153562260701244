import './Header.scss';

function Header() {
  const handleScroll = (section) => {
    const element = document.getElementById(section.toLowerCase());
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="front-page-header">
      <div className="logo-container"><img className="header-logo" src="/a-favi.svg" alt="" /></div>
      <div className="header-content-container">
        <div className="navbar-container">
          <div className="navbar-option" onClick={() => handleScroll('home')}>Home</div>
          <div className="navbar-option" onClick={() => handleScroll('experiences')}>Experience</div>
          <div className="navbar-option" onClick={() => handleScroll('projects')}>Projects</div>
          <div className="navbar-option" onClick={() => handleScroll('skills')}>Skills</div>
          <div className="navbar-option" onClick={() => handleScroll('contact')}>Contact</div>
        </div>
      </div>
    </div>
  );
}

export default Header;
