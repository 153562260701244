// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.skill-block-body {
  flex-shrink: 0;
  margin: 0 auto;
  width: 215px;
  height: 215px;
  background: linear-gradient(rgba(22, 22, 29, 0.85), rgba(30, 30, 40, 0.85));
  transition: width 1s;
  border-radius: 35px;
}
.skill-block-body:hover {
  width: 240px;
}

.skill-title {
  padding: 15px;
  color: #FAFAFA;
  font-family: "Open Sans", "Times New Roman", Times, serif;
  font-size: 28px;
  font-weight: bold;
}

.skill-svg-container {
  display: flex;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/components/cards/skill-block/SkillBlock.scss","webpack://./src/global-styles/global-variables.scss"],"names":[],"mappings":"AAEA;EACI,cAAA;EACA,cCgCY;ED/BZ,YAAA;EACA,aAAA;EACA,2ECUmB;EDTnB,oBAAA;EACA,mBAAA;AADJ;AAGI;EACI,YAAA;AADR;;AAKA;EACI,aAAA;EACA,cCLO;EDMP,yDCGU;EDFV,eCUc;EDTd,iBAAA;AAFJ;;AAKA;EACI,aAAA;EACA,uBAAA;AAFJ","sourcesContent":["@import '../../../global-styles/global-variables.scss';\n\n.skill-block-body {\n    flex-shrink: 0;\n    margin: $header-margin;\n    width: 215px;\n    height: 215px;\n    background: $info-card-background;\n    transition: width 1s;\n    border-radius: 35px;\n\n    &:hover {\n        width: 240px;\n    }\n}\n\n.skill-title {\n    padding: 15px;\n    color: $sea-salt;\n    font-family: $header-text;\n    font-size: $card-subheading;\n    font-weight: bold;\n}\n\n.skill-svg-container {\n    display: flex;\n    justify-content: center;\n}\n","// colors\n$night-5: #0b0b0e;\n$raisin-black-14: rgb(22, 22, 29);\n$raisin-black-10: rgb(30, 30, 40);\n$raisin-black-20: rgb(44, 44, 58);\n$gun-metal-13: #1c2828;\n$gun-metal-15: #1c2b31;\n$gun-metal-18: #223438;\n$paynes-gray: #607680;\n$paynes-gray-50p: rgb(96, 118, 128, 0.5);\n$paynes-gray-75p: rgb(96, 118, 128, 0.75);\n$taupe-gray: #98928f;\n$silver: #bbb6b4;\n$sea-salt: #FAFAFA;\n$white: #FFFFFF;\n\n//background\n$info-card-background: linear-gradient(rgb(22, 22, 29, 0.85), rgb(30, 30, 40, 0.85));\n$info-card-background-hover: linear-gradient($raisin-black-10, $raisin-black-20);\n\n// fonts\n$body-text: \"Instrument Sans\", sans;\n$header-text: 'Open Sans', 'Times New Roman', Times, serif;\n$card-text: Verdana;\n\n//font size\n$section-header-size: 80px;\n$large-header-size: 60px;\n$header-size: 40px;\n$card-header-size: 40px;\n$card-subheading: 28px;\n$card-text: 18px;\n$card-link: 12px;\n\n//card sizes\n$card-margin: 15px auto;\n$header-margin: 0 auto;\n$card-padding: 40px;\n$card-width: 50%;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
