import './SkillBlock.scss'
import { ReactSVG } from 'react-svg';

const SkillBlock = ({name, svgUrl}) => {
  return (
    <div className="skill-block-body">
        <div className="skill-title">{name}</div>
        <div className="skill-svg-container">
            <ReactSVG src={svgUrl} />
        </div>
    </div>
  );
};

export default SkillBlock;