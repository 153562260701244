import './page-styles/App.scss';
import '../components/header/Header';
import ExperiencesCard from '../components/cards/experiences-card/ExperiencesCard';
import Header from '../components/header/Header';
import IntroCard from '../components/cards/intro-card/IntroCard';
import ProjectsCard from '../components/cards/projects-card/ProjectsCard';
import { website_content } from '../descriptions';
import Footer from '../components/footer/Footer';
import Contact from '../components/contact/Contact';
import SkillBlock from '../components/cards/skill-block/SkillBlock';
import Loading from './Loading';
import { useState, useEffect, useRef } from 'react';
import TopButton from '../components/top-button/TopButton';

function App() {
  const [showScrollIcon, setShowScrollIcon] = useState(false);
  const [isAnimated, setIsAnimated] = useState(false);
  const [loading, setLoading] = useState(true); // Loading state

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false); // Set loading to false after 2 seconds (or your desired loading time)
    }, 2000); // Adjust the duration as necessary

    setIsAnimated(true);
    
    const handleScroll = () => {
      if (window.scrollY > 200) {
        setShowScrollIcon(true);
      } else {
        setShowScrollIcon(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      clearTimeout(timer); // Cleanup timer
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const skillBlocksRef = useRef();

  const scrollLeft = () => {
    skillBlocksRef.current.scrollBy({
      left: -skillBlocksRef.current.offsetWidth / 3,
      behavior: 'smooth',
    });
  };

  const scrollRight = () => {
    skillBlocksRef.current.scrollBy({
      left: skillBlocksRef.current.offsetWidth / 3,
      behavior: 'smooth',
    });
  };

  // Render the loading screen if loading is true
  if (loading) {
    return <Loading />;
  }

  return (
    <div id="top" className="App">
      <div className="intro-container">
        <Header />
        <div className="header-image-container">
        <div className={`text-container ${isAnimated ? 'animate-grow' : ''}`}>
          {("Hi, My Name is Aiden.").split('').map((letter, index) => (
            <span 
              className="letter" style={{animationDelay: `${index * 0.1}s`}}>{letter === ' ' ? '\u00A0' : letter}</span>
          ))}
        </div>
          <img 
            className="image" 
            src="/header-image-AI.jpeg" 
            alt="header image"
          />
          
          <div className="header-img-gradient" />
        </div>
      </div>

      <div className={`site-content-container ${isAnimated ? 'animate-grow' : ''}`}>
        <div id="home" className="info-card-container">
          <IntroCard 
            title={website_content.Intro.title}
            paragraphs={website_content.Intro.paragraphs}  
          />

          <div id="experiences" className="front-page-section-header">          
            {("EXPERIENCE").split('').map((letter, index) => (
              <span className="letter" style={{animationDelay: `${index * 0.1}s`}}>{letter === ' ' ? '\u00A0' : letter}</span>
            ))}
          </div>

          {website_content.Experiences.map((experience, index) => (
            <ExperiencesCard
              key={index}
              company={experience.company}
              date={experience.date}
              role={experience.role}
              description={experience.description}
            />
          ))}

          <div id="projects" className="front-page-section-header">
            {("PROJECTS").split('').map((letter, index) => (
              <span className="letter" style={{animationDelay: `${index * 0.1}s`}}>{letter === ' ' ? '\u00A0' : letter}</span>
            ))}
          </div>

          {website_content.Projects.map((project, index) => (
            <ProjectsCard
              key={index}
              title={project.name}
              date={project.date}
              description={project.description}
              link={project.link}
            />
          ))}
        </div>

        <div className="skills-section-container" title="Scroll horizontally for my skills!">
          <div id="skills" className="front-page-section-header">
            {("SKILLS").split('').map((letter, index) => (
              <span className="letter" style={{animationDelay: `${index * 0.1}s`}}>{letter === ' ' ? '\u00A0' : letter}</span>
            ))}
          </div>


          <div className="skills-clue">{"(Scroll right to see all of them!)"}</div>

          <div className="skills-body-container">
            <div className="skill-blocks-container" ref={skillBlocksRef} style={{ animation: "scroll 20s linear infinite" }}>
              {website_content.Skills.map((skill, index) => (
                <SkillBlock
                  key={index}
                  name={skill.name}
                  svgUrl={skill.logo}
                />
              ))}
            </div>
            <div className="skill-arrows-container">
              <div className="skill-arrow" onClick={scrollLeft}>
                <div className="skill-arrow-left">&#x2190;</div>
              </div>
              <div className="skill-arrow" onClick={scrollRight}>
                <div className="skill-arrow-right">&#x2192;</div>
              </div>
            </div>
          </div>
        </div>

        <div className="contact-section-container">
          <div id="contact" className="front-page-section-header">
            {("CONTACT").split('').map((letter, index) => (
              <span className="letter" style={{animationDelay: `${index * 0.1}s`}}>{letter === ' ' ? '\u00A0' : letter}</span>
            ))}
          </div>
          <Contact />
        </div>
      </div>

      <div className="end-overlap">
        <div className="contact-image-container">
          <div className="contact-img-gradient"></div>

          <div className="image-container">
            <img 
              className="header-image" 
              src="/contact-img-AI.jpg" 
              alt="header image"
            />
          </div>
        </div>
        <Footer />
      </div>

      <TopButton />
    </div>
  );
}

export default App;