// src/components/loading/Loading.js
import './page-styles/Loading.scss'; // Keep your existing styles

const Loading = () => {
  return (
    <div className="loading-screen">
      <div className="loading-image-container">
        <img src="/a-favi.svg" alt="A" className="loading-image" />
      </div>
      <div className="loading-dots">
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
      </div>
    </div>
  );
};

export default Loading;
