import '../Card.scss'
import './IntroCard.scss'

const IntroCard = ({title, paragraphs}) => {
  return (
    <div className="card-body">
        <div className="intro-card-content-container">
            <div className="intro-card-header">{title}</div>
            <div className="intro-card-body-container">
                <div className="intro-card-text-body">
                    {paragraphs.map((paragraph) => <p>{paragraph}</p>)}
                </div>

                <div className="intro-card-image-container">
                    <img 
                        className="headshot" 
                        src="/thumbnail-crop.jpg" 
                        alt="aiden livingston head shot" 
                    />
                </div>
            </div>
        </div>
    </div>
  );
};

export default IntroCard;