import './Contact.scss';
import { useState, useRef } from 'react';
import emailjs from 'emailjs-com';

const Contact = () => {
  const [formData, setFormData] = useState({
    email: '',
    subject: '',
    body: ''
  });
  const [success, setSuccess] = useState(false);
  const formRef = useRef();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_1yj83ye', 'template_ta4v6vb', formRef.current, 'XxH9FIj-cSLiUxxtE')
      .then(
        () => {
          setSuccess(true);
          setFormData({ email: '', subject: '', body: '' });
        },
        (error) => {
          console.log('failed...', error.text);
        }
      );
  };

  return (
    <div className="contact-wrapper">
      <form ref={formRef} className="contact-container" onSubmit={handleSubmit}>
        <div className="contact-header-container">
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Email"
            className="contact-email"
            required
          />
          <input
            type="text"
            name="subject"
            value={formData.subject}
            onChange={handleChange}
            placeholder="Subject"
            className="contact-subject"
            required
          />
        </div>
        <div className="contact-body-container">
          <textarea
            name="body"
            value={formData.body}
            onChange={handleChange}
            placeholder="Message"
            className="contact-body"
            required
          />
        </div>
        <button type="submit" className="contact-submit">Send</button>
      </form>
      
      {success && <div className="success-indicator">Email sent successfully!</div>}
    </div>
  );
};

export default Contact;
