export const website_content = {
  "Intro": {
      "title": "I'm an Aspiring Software Engineer.",
      "paragraphs": [
        "I've been a problem solver from day one, from solving jigsaw puzzles on my parent's floor as a toddler to solving complex software architecture issues.",
        "With my background in computer science and hands-on experience in software development, I am passionate about contributing to tackling modern software engineering challenges.",
      ]
  },
  "Experiences": [
    {
      "company": "Dun & Bradstreet",
      "date": "June 2024 - August 2024",
      "role": "Software Engineering Intern",
      "description": [
        "Worked with business analysts from the finance and risk analytics (FRS) fintech project to manage their Jira.",
        "Utilized Java and Angular to solve full-stack user stories, implementing source code for customers to interact with company APIs through the website and command line, increasing customer value.",
        "Groomed a Jira board of 30+ irrelevant user stories, drafting new ones from developer and product requirements.",
        "Learned about end-to-end deployment, quality assurance, the software development life cycle, and CI/CD using tools like Kubernetes, Jenkins, and TerraForm.",
        "Participated in Scrum and DevOps standups to facilitate project sprints, requirement compliance, and feature deployment."
      ]
    },
    {
      "company": "Theta Tau",
      "date": "January 2023 - May 2023",
      "role": "Web Master",
      "description": [
        "Maintained the chapter's website, ensuring up-to-date information for 350 total members, including alumni, elected chairs, e-board positions, and potential new members.",
        "Leveraged Figma to contribute to the front-end design process and UI/UX of the upcoming website revamp.",
        "Researched potential web development technologies with a cross-functional team of 3 former web chairs to adopt an innovative front-end and back-end technologies, including TypeScript, Node, React, Express, Firebase, and Tailwind CSS."
      ]
    },
    {
      "company": "Enterprise Integration",
      "date": "January 2023 – May 2023",
      "role": "Information Technology Intern",
      "description": [
        "Facilitated transfer and troubleshooting of the company knowledge base from one software to another (ITBoost to ITGlue).",
        "Employed Python Scripts to download and modify Excel CSV data to keep over 100+ pages of documentation in its original architecture and content.",
        "Aided design of security grouping for 300+ passwords and other sensitive data points for different authority clearances (Technicians, Service desk, etcetera)."
      ]
    }
    

  ],
  "Projects": [
    {
      "name": "Elevator Kernel Module",
      "date": "September 2023 – December 2023",
      "description": [
        "Installed an Ubuntu virtual machine to download and modify a custom Linux kernel.",
        "Implemented an elevator kernel module using operating systems concepts such as multithreading, mutual exclusion, inter-process communication, background processing, and OS screenshotting.",
        "Programmed 500+ lines of C code to implement a FIFO algorithm to handle arbitrary amounts of interrupts and processes for the elevator to interact with passengers/threads."
      ],
      "link": "https://github.com/FSU-COP4610-F23/proj2-group-32 "
    },
    {
      "name": "FlashCardio",
      "date": "September 2024 - Present",
      "description": [
        "Developed a web-based flashcard  application using Firebase, Express.js, React.js, and Node.js (FERN stack), aimed at improving the organization, creation, and learning of flashcards.",
        "Implemented a hierarchical folder system for the ability to nest folders and decks, allowing users to organize flashcards with greater flexibility",
        "Developed a spaced repetition algorithm that enhances learning by adapting to user flashcard familiarity, tracking user progress, and prioritizing cards to maximize retention.",
      ],
      "link": " https://github.com/DHaire92/Flash-Cardio"
    },
    {
      "name": "Chord Generator",
      "date": " February 2024 – March 2024",
      "description": [
        "Developed a chord generator program that generates all possible guitar chord voicings.",
        "Created an algorithm that validates each chord using prime decomposition techniques, storing the valid chords in a database for future use.",
        "Built modular classes for the guitar fretboard, chords, and database management, with functionality for chord validation, string position tracking, and chord type identification.",
        "Implemented custom data structures to efficiently generate and manage chord voicings."
      ],
      "link": "https://github.com/lvngstn/chord-check"
    },
    {
      "name": "aiden-living.us",
      "date": "October 2024 - Present",
      "description": [
        "Designed and developed a personal portfolio website using React.js, Sass, and CSS to showcase projects, experiences, and skills.",
        "Implemented a responsive layout, utilizing custom components for sections like projects, experiences, skills, and contact information.",
        "Deployed the website on Heroku, and connected the domain aiden-living.us for professional online presence.",
        "Created a modular codebase to easily update and manage content, with dynamic components for future scalability and customization."
      ],
      "link": "https://aiden-living.us"
    }
  ],
  "Skills" : [
    {
      "name": "Python",
      "logo": "/skill-svgs/python.svg"
    },
    {
      "name": "Java",
      "logo": "/skill-svgs/java.svg"
    },
    {
      "name": "HTML",
      "logo": "/skill-svgs/html.svg"
    },
    {
      "name": "CSS",
      "logo": "/skill-svgs/css.svg"
    },
    {
      "name": "Javascript",
      "logo": "/skill-svgs/javascript.svg"
    },
    {
      "name": "React",
      "logo": "/skill-svgs/react.svg"
    },
    {
      "name": "Node.js",
      "logo": "/skill-svgs/node.svg"
    },
    {
      "name": "C",
      "logo": "/skill-svgs/c.svg"
    },
    {
      "name": "C++",
      "logo": "/skill-svgs/cpp.svg"
    },
    {
      "name": "GitHub",
      "logo": "/skill-svgs/github.svg"
    },
    {
      "name": "VS Code",
      "logo": "/skill-svgs/visual-studio-code.svg"
    },
    {
      "name": "Jira",
      "logo": "/skill-svgs/jira.svg"
    },
    {
      "name": "Firebase",
      "logo": "/skill-svgs/firebase.svg"
    },
    {
      "name": "Mongo DB",
      "logo": "/skill-svgs/mongodb.svg"
    },
    {
      "name": "Express.js",
      "logo": "/skill-svgs/express.svg"
    },
    {
      "name": "MS Office",
      "logo": "/skill-svgs/office.svg"
    },
    // {
    //   "name": "",
    //   "logo": "/skill-svgs/.svg"
    // },
  ]
}