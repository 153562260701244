import '../Card.scss';
import './ExperiencesCard.scss';

function ExperiencesCard({ company, role, date, description }) {
    return (
        <div className="card-body">
            <div className="info-card-content-container">
                <div className="info-card-header-container">
                    <div className="info-card-title-container">
                        <div className="info-card-title">{company}</div>
                        <div className="info-card-subtitle">{role}</div>
                    </div>
                    <div className="info-card-date">{date}</div>
                </div>
                
                <ul className="info-card-text">
                    {description.map((bullet, index) => (
                        <li key={index}>
                            <td className="info-card-bullet">{bullet}</td>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}

export default ExperiencesCard;
