import './ProjectsCard.scss'
import '../Card.scss'

const ProjectsCard = ({title, date, description, link}) => {
  return (
    <div className="card-body">
        <div className="projects-card-content-container">
            <div className="projects-card-header-container">
                <div className="projects-card-title-container">
                    <div className="projects-card-title">{title}</div>
                </div>
                <div className="projects-card-date">{date}</div>
            </div>
            <ul className="projects-card-text">
                {description.map((bullet, index) => (
                    <li key={index}>
                        <td className="projects-card-bullet">{bullet}</td>
                    </li>
                ))}
            </ul>
            <div className="project-link-container">
                <div className='project-link-indicator'>Link:</div>
                <a href={link} target="_blank" className="project-link">{link}</a>
            </div>
        </div>
    </div>
  );
};

export default ProjectsCard;