import './TopButton.scss'
import { useEffect, useState } from 'react';

const TopButton = () => {
    const [isVisible, setIsVisible] = useState(false);

    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setIsVisible(scrollPosition > 300); // Show button after scrolling down 300px
    };
  
    useEffect(() => {
      window.addEventListener('scroll', handleScroll);
      
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);
  
    return (
      <div className={`scroll-to-top ${isVisible ? 'visible' : ''}`} onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
        <svg width="35" height="35" viewBox="0 0 115 125" xmlns="http://www.w3.org/2000/svg">
            <title>up-arrow</title>
            <path d="M24.94,67.88A14.66,14.66,0,0,1,4.38,47L47.83,4.21a14.66,14.66,0,0,1,20.56,0L111,46.15A14.66,14.66,0,0,1,90.46,67.06l-18-17.69-.29,59.17c-.1,19.28-29.42,19-29.33-.25L43.14,50,24.94,67.88Z" fill="#FAFAFA"/>
        </svg>
      </div>
    );
};

export default TopButton;