// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-body {
  display: flex;
  justify-content: center;
  margin: 15px auto;
  width: 50%;
  background: linear-gradient(rgba(22, 22, 29, 0.85), rgba(30, 30, 40, 0.85));
  transition: width 1s ease-in-out;
  border-radius: 35px;
}

.card-body:hover {
  width: 60%;
}`, "",{"version":3,"sources":["webpack://./src/components/cards/Card.scss","webpack://./src/global-styles/global-variables.scss"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,uBAAA;EACA,iBC8BU;ED7BV,UCgCS;ED/BT,2ECUmB;EDTnB,gCAAA;EACA,mBAAA;AADJ;;AAIA;EACI,UAAA;AADJ","sourcesContent":["@import '../../global-styles/global-variables.scss';\n\n.card-body {\n    display: flex;\n    justify-content: center;\n    margin: $card-margin;\n    width: $card-width;\n    background: $info-card-background;\n    transition: width 1s ease-in-out;\n    border-radius: 35px;\n}\n\n.card-body:hover {\n    width: 60%;\n}","// colors\n$night-5: #0b0b0e;\n$raisin-black-14: rgb(22, 22, 29);\n$raisin-black-10: rgb(30, 30, 40);\n$raisin-black-20: rgb(44, 44, 58);\n$gun-metal-13: #1c2828;\n$gun-metal-15: #1c2b31;\n$gun-metal-18: #223438;\n$paynes-gray: #607680;\n$paynes-gray-50p: rgb(96, 118, 128, 0.5);\n$paynes-gray-75p: rgb(96, 118, 128, 0.75);\n$taupe-gray: #98928f;\n$silver: #bbb6b4;\n$sea-salt: #FAFAFA;\n$white: #FFFFFF;\n\n//background\n$info-card-background: linear-gradient(rgb(22, 22, 29, 0.85), rgb(30, 30, 40, 0.85));\n$info-card-background-hover: linear-gradient($raisin-black-10, $raisin-black-20);\n\n// fonts\n$body-text: \"Instrument Sans\", sans;\n$header-text: 'Open Sans', 'Times New Roman', Times, serif;\n$card-text: Verdana;\n\n//font size\n$section-header-size: 80px;\n$large-header-size: 60px;\n$header-size: 40px;\n$card-header-size: 40px;\n$card-subheading: 28px;\n$card-text: 18px;\n$card-link: 12px;\n\n//card sizes\n$card-margin: 15px auto;\n$header-margin: 0 auto;\n$card-padding: 40px;\n$card-width: 50%;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
