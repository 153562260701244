import './Footer.scss'

const Footer = () => {
  return (
    <div className="footer-body">
        <div className="footer-credits">Aiden Livingston | 2024</div>
        <div className="footer-socials-container">
            <div className="footer-socials">
                <a href="https://www.linkedin.com/in/aiden-l/" target="_blank" className="footer-social-link">LinkedIn</a>
                <a href="https://github.com/lvngstn" target="_blank" className="footer-social-link">GitHub</a>
                <a href="https://www.instagram.com/adnlvngstn/" target="_blank" className="footer-social-link">Instagram</a>
            </div>
        </div>
    </div>
  );
};

export default Footer;